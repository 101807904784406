/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.menu-icon {
  width: 100px;
  position: relative;
  top: 1px;
}

.primary-toolbar {
  --background: var(--ion-color-primary);
}

.custom-background {
  --background: #5dd180;
}

*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Manulife";
}

@font-face {
  font-family: "Manulife";
  src: url("assets/font/Manulife.ttf");
}
@font-face {
  font-family: "NotoSansMyanmar";
  src: url("./assets/font/NotoSansMyanmar.ttf");
}
.mmfont {
  font-family: "NotoSansMyanmar" !important;
}

.enfont {
  font-family: "Manulife" !important;
}

ion-toast {
  font-family: "NotoSansMyanmar" !important;
}

ion-datetime {
  font-family: "NotoSansMyanmar" !important;
}
ion-datetime button {
  font-size: 15px !important;
}

ion-alert .alert-message {
  font-family: "NotoSansMyanmar" !important;
}

ion-toolbar {
  height: 53px !important;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
}
.error-page .not-found {
  width: 300px;
}
.error-page .manu-icon {
  width: 100px;
}
.error-page .text {
  font-size: 25px;
  font-weight: normal;
  color: #343434;
}
.error-page .text span {
  font-size: 16px;
  position: relative;
  top: 10px;
  color: #dc3545;
}

.button-container ion-icon {
  position: relative;
  top: -1px;
  left: 3px;
}

ion-title {
  cursor: pointer;
}

#back-btn {
  color: white;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.swiper-slide {
  flex-direction: column !important;
  min-height: 65vh;
  justify-content: space-between;
  gap: 15px;
}

.terms p {
  margin-top: 0px !important;
}

.content {
  padding: 8px;
  margin: auto;
}

.center {
  text-align: center;
}

.heading-title {
  text-align: center;
  font-weight: 600;
}

.form .form-group {
  margin-bottom: 20px;
}
.form .form-group p {
  margin: 0;
  margin-bottom: 10px;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 9px;
  margin-bottom: 0px;
}

.tab {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
}
.tab .tab-item {
  width: 32%;
}
.tab .tab-item p {
  margin-top: 0px;
  color: #5a5a5a;
}
.tab .tab-item .item-bar {
  background: #efefef;
  width: 100%;
  height: 5px;
  border-radius: 100px;
}

.active p {
  color: var(--ion-color-primary) !important;
  font-weight: 600;
}
.active .item-bar {
  background: var(--ion-color-primary) !important;
}

ion-alert {
  z-index: 999999 !important;
}

.footer {
  z-index: 2;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 5px;
  background: rgba(255, 255, 255, 0.6588235294);
  border-top: 1px solid rgba(0, 0, 0, 0.1019607843);
  -webkit-backdrop-filter: saturate(160%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 375px) {
  .swiper-slide {
    min-height: 60vh;
  }
}
@media screen and (max-width: 1200px) {
  .swiper-slide {
    justify-content: space-around;
  }
  .content {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .content {
    width: 50%;
  }
  .footer {
    position: unset;
    display: flex;
    margin: auto;
    margin-top: 15px;
    justify-content: center;
  }
  .footer ion-button {
    width: 170px;
  }
  .content-res {
    width: 60% !important;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
  }
  .footer {
    border: none;
  }
}
@media screen and (max-width: 311px) {
  .tab {
    font-size: 11px;
  }
}
ion-date-time {
  font-family: "Manulife" !important;
}